import Vue from 'vue/dist/vue.esm';
import utils from '../pricing/providers/utils';
import VueI18n from 'vue-i18n';
import WashyContainer from '../washy/WashyContainer.vue';
import { client } from '../pricing/providers/apollo';

const initialize = function () {
  const washyContainer = document.querySelector('#washy-app');

  if (washyContainer) {
    Vue.use(VueI18n);
    Vue.use({
      install: Vue => {
        Vue.prototype.$utils = utils(Vue);
      },
    });

    Vue.prototype.$client = client;

    const i18n = new VueI18n({
      locale: window.locale,
      messages: window.localeData,
      fallbackLocale: 'en',
    });

    const app = new Vue({
      render: h => h(WashyContainer),
      i18n,
    }).$mount('#washy-app');
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initialize);
} else {
  initialize();
}
