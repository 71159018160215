<script>
import PriceBotUpsell from '../pricing/components/category_details/views/PriceBotUpsell.vue';
import BotModal from '../pricing/components/outlets/BotModal.vue';
import shortid from 'shortid';
import analyticsMixins from '../pricing/mixins/analyticsMixins';

export default {
  mixins: [analyticsMixins],
  data() {
    return {
      showWashy: false,
      modals: [],
      closeCallback: null,
      showWashyUpsell: false,
    };
  },
  components: { PriceBotUpsell, BotModal },
  async created() {
    this.showWashyUpsell = window.showWashyUpsell;
    this.isFeatureEnabled('web-washy').then(enabled => {
      this.showWashy = enabled;
      if (enabled) {
        window.openWashy = this.openWashyExternal;
      }
    });
  },
  methods: {
    addModal(component, data, title) {
      const id = shortid();
      this.modals.push({
        component,
        id,
        data: {
          ...data,
          id,
        },
        title,
        noPadding: !!data.noPadding,
      });
    },
    openPricingAssistant() {
      startChat();
      this.addModal('BotModal', { noPadding: true });
    },
    removeModal(id) {
      for (var i = 0; i < this.modals.length; i++) {
        if (this.modals[i].id == id) {
          this.modals.splice(i, 1);
          if(this.closeCallback) {
            this.closeCallback();
            window.washyIntercomOpened = false;
          }
          break;
        }
      }
    },
    openWashyExternal(callback) {
      if(this.modals.length > 0) {
        if (this.closeCallback) {
          this.modals = []
          this.closeCallback();
        }
        return
      }
      this.closeCallback = callback
      this.openPricingAssistant()
    }
  },
};
</script>

<template>
  <div class="col-lg-5 col-12 m-auto">
    <PriceBotUpsell v-if="showWashy && showWashyUpsell" @click="openPricingAssistant"></PriceBotUpsell>

    <component
      v-for="modal in modals"
      :key="modal.id"
      :id="modal.id"
      :title="modal.title"
      :no-padding="modal.noPadding"
      :is="modal.component"
      @close="removeModal"
      :data="modal.data"
      :removeModal="removeModal"
    >
    </component>
  </div>
</template>
